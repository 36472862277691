import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="bg-[url('/src/assets/background.jpg')] bg-center bg-no-repeat min-w-screen bg-cover h-screen flex flex-col">
      <h1 className="text-3xl font-bold w-screen text-center text-white py-10 bg-black/60">
        COMING SOON! WEBSITE UNDER DEVELOPMENT
      </h1>
      <div className="grow"></div>
      <div className="flex flex-col sm:flex-row items-center justify-center py-16 sm:space-x-10 space-y-4 sm:space-y-0">
        <a
          href="https://www.twitter.com/dogwifbeater"
          className="text-3xl font-bold bg-gradient-to-b from-yellow-400 to-red-800 w-52 h-20 rounded-xl border-4 border-black flex items-center justify-center"
        >
          Twitter
        </a>
        <a
          href="https://www.t.me/dogwifbeater"
          className="text-3xl font-bold bg-gradient-to-b from-yellow-400 to-red-800 w-52 h-20 rounded-xl border-4 border-black flex items-center justify-center"
        >
          Telegram
        </a>
      </div>
    </div>
  );
}

export default App;
